<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto">
      <div id="body-content-area" style="overflow: hidden">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div v-else>
          <div id="pagelayout">
            <div v-if="errors && errors.length">
              <div v-for="error in errrors" :key="error">{{ error }}</div>
            </div>

            <div v-if="states && states.length > 0">
              <div class="grid-container">
                <div class="bottom-bar">
                  <div class="buttons">
                    <button
                      :disabled="
                        !$hasPermissions(clientSession, ['REPORT_CONFIGURATION'], 2)
                      "
                      @click="
                        $router.push({
                          name: 'NewStateConfig',
                          model: null,
                        })
                      "
                      class="button is-accent"
                    >
                      <i
                        class="fa fa-plus"
                        style="margin-right: 0.75rem; margin-left: 0"
                      ></i
                      >Add State
                    </button>
                  </div>
                </div>
                <Grid
                class="states-grid"
                  v-if="maxHeight > 0"
                  @headerselectionchange="onHeaderSelectionChange"
                  @selectionchange="onSelectionChange"
                  :style="{
                    maxheight: maxHeight + 'px',
                    height: maxHeight + 'px',
                  }"
                  :data-items="states"
                  :columns="columns"
                  :selected-field="selectedField"
                  :sortable="true"
                  :pageable="false"
                  :page-size="minResults"
                  :total="totalRecords"
                  :sort="sort"
                  @pagechange="pageChangeHandler"
                  @sortchange="sortchange"
                >
                  <template v-slot:nameCell="{ props }">
                    <td>
                      <router-link
                        class="has-text-accent"
                        :to="{
                          name: 'StateConfigEdit',
                          params: {
                            id: `${props.dataItem.stateKey}`,
                            model: `${JSON.stringify(props.dataItem)}`,
                          },
                        }"
                      >
                        {{ props.dataItem.stateAbbreviation }}
                      </router-link>
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import Loading from './../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
// import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { Grid } from '@progress/kendo-vue-grid'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import _ from 'lodash'

let pageName = 'States Management'
// Vue.use(GridInstaller)

export default {
  components: {
    Loading,
    Grid: Grid
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      confirmationText: '',
      showDeleteModal: false,
      columns: [
        // { selectable: true, field: 'selected', width: '0px' },
        { field: 'stateAbbreviation', title: 'Abbreviation', cell: 'nameCell', width: '120px' },
        { field: 'stateName', title: 'Name' }
      ],
      sort: [{ field: 'name', dir: 'asc' }],
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      selectedRows: [],
      filter: {
        logic: 'and',
        filters: []
      },
      pagination: {
        skip: 0,
        take: 5
      },
      totalRecords: 0,
      institutions: null,
      isLoading: true,
      selectedField: 'selected',
      states: [],
      isDeleting: false
    }
  },
  watch: {},
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    pageheading () {
      return pageName
    },
    statesList () {
      if (this.states.length === 0) {
        return []
      }

      let filtered = filterBy(this.states, this.filter)
      return orderBy(filtered, this.sort)
    },
    pageSizes () {
      let sizes = [this.minResults]

      sizes.push(this.minResults)
      sizes.push(5)
      sizes.push(10)
      sizes.push(15)
      sizes.push(20)
      sizes.push(50)
      sizes.push(100)

      // remove dups
      sizes = [...new Set(sizes)]

      // return a sorted array from smallest to largest
      return sizes.sort((a, b) => a - b)
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.pagination.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight - 50

        this.getstates()
      }

      that.getListHeights(
        'body-content-area',
        40,
        79,
        elements,
        func.bind(this)
      )

      window.addEventListener('resize', () => {
        that.getListHeights(
          'body-content-area',
          40,
          79,
          elements,
          func.bind(that)
        )
      })
    })
  },
  methods: {
    async deleteIfConfirmed () {
      if (this.confirmationText.toLowerCase() === 'delete') {
        await this.deletestates()
        this.showDeleteModal = false
        this.isLoading = false
      }
    },
    pageChangeHandler (event) {
      this.pagination = {
        skip: event.page.skip,
        take: event.page.take
      }

      this.getstates()
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }

      Vue.set(
        event.dataItem,
        this.selectedField,
        event.dataItem[this.selectedField]
      )
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(
        this,
        'states',
        this.states.map((item) => {
          return { ...item, selected: checked }
        })
      )

      if (checked) {
        this.selectedRows = this.states
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    formatEndDate (format) {
      let dateTime = moment().add(12, 'hours')
      return dateTime.format(format)
    },
    formatStartDate (format) {
      let dateTime = moment()
      return dateTime.format(format)
    },
    sortchange (e) {
      this.sort = e.sort
      this.getstates()
    },
    async getstates () {
      let that = this
      this.isLoading = true
      this.api().getStates(
        (_response, _error) => {
          if (_error) {
          }
          if (_response && _response.length > 0) {
            that.states = _response

            if (this.sort && this.sort.length > 0) {
              that.states = _.sortBy(_response, this.sort[0].field)

              if (this.sort[0].dir === 'desc') {
                that.states = that.states.reverse()
              }
            }

            that.totalRecords = this.states.length

            // console.debug('states', that.states)
            // console.debug('sort', this.sort)
          } else {
            that.states = []
            that.totalRecords = 0
          }

          that.isLoading = false
        }
      )
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-grid td:first-child {
  color: transparent !important;
}

.k-grid th:first-child {
  color: transparent !important;
}

.grid-container {
  margin-bottom: 1rem;
}

.visible {
  visibility: hidden;
}

.bottom-bar {
  margin-top: 0rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: flex-end;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-master-row > td:first-child {
  color: #383838!important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  vertical-align: center !important;
}

.states-grid .k-header:first-child {
  display: flex;
  justify-content: center;
  align-content: center;
  border-width: 0px !important;
}

.states-grid .k-header > a {
  color: #383838!important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  vertical-align: center !important;
}
</style>
